import numeral from 'numeral';

const SCHOOL_YEAR = 2024;

const utils = {
    year: function (offset = 0) {
        return SCHOOL_YEAR + offset;
    },
    yearRange: function (offset = 0) {
        const year = SCHOOL_YEAR + offset;
        const prevYear = year - 1;

        return prevYear + '-' + String(year).substring(2, 4);
    },

    setHomeTitle: function (subText) {
        document.title = 'Tennessee School Report Cards | ' + subText;
    },

    translateCode2Label: function (code, list) {
        let answer = '';
        for (let i = 0; i < list.length; i++) {
            if (list[i].value === code) {
                answer = list[i].label;
                break;
            }
        }
        return answer;
    },

    gradeRange: function (srclists, startCd, endCd) {
        const startGrade = utils.translateCode2Label(startCd, srclists.grades);
        const endGrade = utils.translateCode2Label(endCd, srclists.grades);

        return startGrade === endGrade ? startGrade : startGrade + ' - ' + endGrade;
    },

    formatPhone: function (val) {
        if (!!val) {
            const cleaned = ('' + val).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (!!match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
        } else {
            return '';
        }
    },

    ensureProtocol: function (url) {
        if (!!url) {
            if (url.startsWith('http')) {
                return url;
            } else {
                return 'http://' + url;
            }
        } else {
            return '';
        }
    },

    formatURL: function (val) {
        if (!!val) {
            if (val.startsWith('http://')) return val.substr(7);
            if (val.startsWith('https://')) return val.substr(8);
            else return val;
        } else return '';
    },

    formatNumber: function (format, value, nullVal) {
        if (value === null && !!nullVal) {
            return nullVal;
        } else {
            const formatParts = format.match(/(^\D*)(.*)/);
            const _name = formatParts[1];
            let _decimals = new Array(formatParts[2] || 0).join('0');
            if (!!_decimals) _decimals = '.' + _decimals;

            let _format = '';
            switch (_name) {
                case 'number':
                    _format = '0,0' + _decimals;
                    break;
                case 'percent':
                    _format = '0,0' + _decimals + '%';
                    break;
                case 'dollars':
                    _format = '$0,0' + _decimals;
                    break;
                default:
                    _format = '0' + _decimals;
            }
            return numeral(value).format(_format);
        }
    },
};

export default utils;
