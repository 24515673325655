import { createContext, useContext, useEffect, useState } from 'react';

const SrclistsContext = createContext();

export function useEntity(id) {
    const [entity, setEntity] = useState(null);

    if (id !== entity?.id) {
        fetch('/rest/entity/' + id)
            .then((resp) => resp.json())
            .then((json) => {
                setEntity(json);
            });
        return null;
    }

    return entity;
}

export function useTISA(id) {
    const [tisa, setTISA] = useState({});

    useEffect(() => {
        fetch('/rest/tisa/' + id)
            .then((resp) => resp.json())
            .then((json) => {
                setTISA(json);
            });
    }, [id]);

    return tisa;
}

export function useClusters(id) {
    const [clusters, setClusters] = useState([]);

    useEffect(() => {
        fetch('/rest/clusters/' + id)
            .then((resp) => resp.json())
            .then((json) => {
                setClusters(json);
            });
    }, [id]);

    return clusters;
}

export function useSchoolCount() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        fetch('/rest/count/school')
            .then((resp) => resp.json())
            .then((json) => {
                setCount(json.count);
            });
    }, []);

    return count;
}

export function useDistrictCount() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        fetch('/rest/count/district')
            .then((resp) => resp.json())
            .then((json) => {
                setCount(json.count);
            });
    }, []);

    return count;
}

export function useEntitiesByName(name, onlySchools, onlyDistricts) {
    const [entities, setEntities] = useState([]);

    useEffect(() => {
        if (!!name) {
            const params = new URLSearchParams({ name: name });
            if (onlySchools) params.set('onlySchools', onlySchools);
            if (onlyDistricts) params.set('onlyDistricts', onlyDistricts);

            fetch('/rest/entities?' + params)
                .then((resp) => resp.json())
                .then((json) => {
                    setEntities(json);
                });
        } else {
            setEntities([]);
        }
    }, [name, onlySchools, onlyDistricts]);

    return entities;
}

export function useEntitiesByFilter({ type, filters, homeLocation, radius, name, page, perPage, sort, by }) {
    const [results, setResults] = useState({ entities: [], numPerPage: 0, firstRow: 0, numPages: 0 });

    useEffect(() => {
        // First clean out empty filters
        Object.keys(filters).forEach((key) => {
            if (filters[key].length === 0) {
                delete filters[key];
            }
        });

        const body = {
            filters: filters,
            center: !!homeLocation ? { radius: radius, location: { latitude: homeLocation.location.y, longitude: homeLocation.location.x } } : null,
            name: name,
        };
        const url =
            '/rest/' +
            type +
            '/' +
            (page || 1) +
            '?' +
            (!!sort ? 'sort=' + sort + '&' : '') +
            (!!by ? 'by=' + by + '&' : '') +
            (!!perPage ? 'perPage=' + perPage + '&' : '');

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        })
            .then((resp) => resp.json())
            .then((json) => {
                setResults(json);
            });
    }, [type, filters, homeLocation, radius, name, page, perPage, sort, by]);

    return results;
}

export function useSrclists() {
    return useContext(SrclistsContext);
}

const GRADE_TO_NUM = {
    PK: -1,
    K: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    10: 10,
    11: 11,
    12: 12,
};
export default function DataProvider({ children }) {
    const [srclists, setSrclists] = useState(null);

    useEffect(() => {
        fetch('/rest/srclists')
            .then((resp) => resp.json())
            .then((json) => {
                json.grades = json.grades.sort((a, b) => GRADE_TO_NUM[a.value] - GRADE_TO_NUM[b.value]);
                json.school_types = json.school_types.sort((a, b) => a.label.localeCompare(b.label));
                json.districts = json.districts.sort((a, b) => a.label.localeCompare(b.label));
                json.distinctions_school = json.distinctions_school.filter((ds) => ds.value !== '2');
                json.distinctions_district = json.distinctions_district.filter((ds) => ds.value !== '7');
                json.designation_school = json.designation_school.filter((ds) => ds.value !== '6' && ds.value !== '8');

                setSrclists(json);
            });
    }, []);

    return !!srclists ? <SrclistsContext.Provider value={srclists}>{children}</SrclistsContext.Provider> : null;
}
